//// key
/// sd - self-described
////

import React from 'react';

/**
 * sd
 * @param obj
 * @returns {any}
 * by kevinbarasa
 */
export const getObjectClone = obj => {
    return Object.defineProperties({}, Object.getOwnPropertyDescriptors(obj));
};

/**
 * sd
 * @param obj
 * @returns {string}
 * by kevinbarasa
 */
export function stringifyObject(obj) {
    return JSON.stringify(obj);
}

/**
 * sd
 * @param item
 * by kevinbarasa
 */
export function isNullUndefined(item) {
    try {
        return item === null || item === undefined;
    } catch (err) {
        return true;
    }
}

/**
 * sd
 * @param data
 * @param uniqueKey
 * by kevinbarasa
 */
export const persistToLocalStorage = (data, uniqueKey) => {
    try {
        if (!isNullUndefined(window)) {
            window.localStorage.setItem(uniqueKey, stringifyObject(data));
        }
    } catch (err) {
        alert('Persist failure!!');
    }
};

/**
 * sd
 * @returns {any}
 * @param uniqueDataStoreKey
 * by kevinbarasa
 */
export const persistedItemInLocalStorage = (uniqueDataStoreKey) => {
    if (!isNullUndefined(window)) {
        let jsonData = JSON.parse(window.localStorage.getItem(uniqueDataStoreKey));
        return jsonData;
    }
};

/**
 * sd
 * @param array
 * @returns {boolean}
 * by kevinbarasa
 */
export function isEmptyArray(array) {
    try {
        if (isNullUndefined(array)) {
            return true;
        } else {
            return !(array instanceof Array && array.length > 0);
        }
    } catch (err) {
        return true;
    }
}

/**
 * sd
 * @param value
 * @returns {boolean}
 * by kevinbarasa
 */
export function isEmptyString(value) {
    try {
        if (typeof value !== "string") {
            return true;
        }
        return value.trim() === "";
    } catch (err) {
        return true;
    }
}

/**
 * sd
 * @param paragraphsList
 * @returns {[]}
 * by kevinbarasa
 */
export function concatenateContentfullParagraphs(paragraphsList) {

    let paragraph = (data, mark) => (
        <p className={mark ? 'paragraph_heading' : ''} style={{fontWeight: mark ? mark : ''}}>{data}</p>
    );

    let paragraphParts = [];

    let paragraphTextValueFinder = (item) => {
        let value = null;

        if (isObject(item) && objectKeyExists(item, 'content')) {
            paragraphTextValueFinder(item['content'])
        } else if (!isEmptyArray(item)) {
            for (let child of item) {
                let mark = !isEmptyArray(child['marks']) ? child['marks'][0].type : null;
                value = child['value'];
                paragraphParts.push({p: value, m: mark});
                value && paragraphs.push(paragraph(value, mark));
            }
            paragraphTextValueFinder(item['content'])
        } else {
            if (isObject(item) && objectKeyExists(item, 'value')) {
                value = item['value'];
            }
        }

        return value;
    };

    let paragraphs = [];
    if (!isEmptyArray(paragraphsList)) {
        for (let p of paragraphsList) {
            let value = paragraphTextValueFinder(p);
            value && paragraphs.push(paragraph(value));
        }
    }

    return paragraphs;

}

/**
 * sd
 * @param item
 * @returns {boolean}
 * by kevinbarasa
 */
export function isObject(item) {
    try {
        return item !== null && item !== undefined && typeof item === "object";
    } catch (err) {
        return false;
    }
}


/**
 * self-described
 * @param obj
 * @param key
 * by kevinbarasa
 */
export function objectKeyExists(obj, key) {
    try {
        return obj.hasOwnProperty(key);
    } catch (err) {
        return false;
    }
}

/**
 * sd
 * @param toSortList
 * @param toSortListLength
 * by kevinbarasa
 */
export function bubbleSortItems(toSortList, toSortListLength) {
    
    let temp;
    let iteration;
    let index;
    let sortedList = [];

    for (iteration = 1; iteration < toSortListLength; iteration++) {
        for (index = 0; index < toSortListLength - iteration; index++)
            if (toSortList[index] > toSortList[index + 1]) {
                temp = toSortList[index];
                //NOT IDEAL, BUT WORKS: because of FP principle that function arguments should not be mutated
                toSortList[index] = toSortList[index + 1];
                toSortList[index + 1] = temp;
                //IDEAL: because of FP principle that function arguments should not be mutated
                sortedList.push(toSortList[index + 1]);
            } else {
                //KEEPING UP WITH IDEAL: because of FP principle that function arguments should not be mutated
                sortedList.push(toSortList[index]);
            }
    }

    return toSortList;
    //IDEAL: because of FP principle that function arguments should not be mutated
    // return sortedList;
    
}

