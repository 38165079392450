import React from "react"
import {Link} from "gatsby"
import './navigation.module.css'
import {Icon} from 'react-icons-kit'
import {ic_reorder} from 'react-icons-kit/md/ic_reorder'
import {close} from 'react-icons-kit/fa/close'
import Footer from "./footer";
import {isNullUndefined} from "../util/util";

const window = require("global/window");

export default class Header extends React.Component {

    constructor(data) {
        super(data);
        this.showOrHideMobileMenu = this.showOrHideMobileMenu.bind(this);

        this.state = {
            products: [],
            showMobileMenu: false,
            isDivActive: false
        }
    }

      componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
      }
      
      componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
      }

      listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight 
        const scrollPosition = winScroll / height
        if(scrollPosition > 0.03){
            this.setState({isDivActive: true})
        }else{
            this.setState({isDivActive: false})
        }
      }

    showOrHideMobileMenu() {
        console.log("Btn clicked..");
        const {showMobileMenu} = this.state;
        this.setState({showMobileMenu: !showMobileMenu});
    }

    static menuItems = {
        About: 'About',
        Services: 'Services',
        Projects: 'Projects',
        contactUs: 'contactUs'
    };

    static currentMenuItem = 'xxxxxxxxxxxxxx';

    _selectedMenuItem = () => {

        if (!isNullUndefined(window) && !isNullUndefined(window.location)) {

            let currentLocation = window.location.href;
            // console.log('currentLocation', currentLocation);

            let onHomePage = true;
            Header.currentMenuItem = 'xxxxxxxxxxxxxx';

            for (let key in Header.menuItems) {
                let iterated = Header.menuItems[key];
                // console.log('iterated menu item', iterated);
                if (currentLocation.toUpperCase().includes(iterated.toUpperCase())) {
                    // console.log('IT HAS');
                    Header.currentMenuItem = iterated;
                    onHomePage = false;
                    break;
                }
            }
            // console.log('Header.currentMenuItem', Header.currentMenuItem);
            return [Header.currentMenuItem, onHomePage];

        }
        return [];

    };

    render() {
        const {showMobileMenu} = this.state;

        let will_apply_active_menu = menu => {
            if (!isNullUndefined(this._selectedMenuItem()[0]) && this._selectedMenuItem()[0].toUpperCase().includes(menu.toUpperCase())) {
                return 'active-menu';
            }
            return '';
        };

        let will_apply_active_home_menu = menu => {
            if (!isNullUndefined(this._selectedMenuItem()[1]) && this._selectedMenuItem()[1] && !window.location.href.toUpperCase().includes("project".toUpperCase())) {
                return 'active-menu';
            }
            return '';
        };

        return (
            <div className={`shrink ${this.state.isDivActive ? "active": ""}`}>


                <div className={"navigation_wrapper"}>
                    <div className="row">
                        <div className="col-lg-4 col-md-1 col-sm-6 col-6">

                            <Link title="Home" aria-label="Home" to="/"><span className={"logo"}>
                                    <img src={"https://images.ctfassets.net/x5zolfqdr3vy/7eheb9RcOCKu3geoOPracD/df8d3dac92f5c92c9d8fc6a8c7a81b67/tslogo.png"} />
                            </span></Link>
                        </div>
                        <div className="col-lg-8 col-md-11 col-sm-6 col-6">
                            <ul className={"hide_for_small"}>
                                <li>
                                    <Link title="Home" aria-label="Home" to="/"
                                          className={`${will_apply_active_home_menu('home')}`}
                                    >Home</Link>
                                </li>
                                <li>
                                    <Link title="About" aria-label="About" to="/about"
                                          className={`${will_apply_active_menu('about')}`}
                                    >About</Link>
                                </li>
                                <li>
                                    <Link title="Services" aria-label="Services" to="/services"
                                          className={`${will_apply_active_menu('services')}`}
                                    >Services</Link>
                                </li>
                                <li>
                                    <Link title="Projects" aria-label="Projects" to="/projects"
                                          className={`${will_apply_active_menu('projects')}`}
                                    >Projects</Link>
                                </li>
                                <li>
                                    <Link title="Contact" aria-label="" to="/contactUs"
                                          className={will_apply_active_menu('contactUs')}
                                          className={`${will_apply_active_menu("contactUs")}`}
                                    >Contact</Link>
                                </li>
                            </ul>

                            {/* mobile navigation */}
                            <div className="mobile_nav">
                                <div className={showMobileMenu == true ? "closeMenu" : "openMenu"}>
                                    <div className="mobile_icon" onClick={this.showOrHideMobileMenu.bind(this)}>
                                        <Icon className="open_icon" icon={ic_reorder}/>

                                    </div>
                                </div>


                                <div className={showMobileMenu == true ? "active" : "hide"}>
                                    <Link title="Home" aria-label="Home" to="/"><span className={"logo"}></span></Link>
                                    <div className={showMobileMenu == true ? "closeMenu" : "openMenu"}>
                                        <div className="mobile_icon" onClick={this.showOrHideMobileMenu.bind(this)}>

                                            <Icon className="close_icon" icon={close}/>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>
                                            <Link title="Home" aria-label="Home" to="/">Home</Link>
                                        </li>
                                        <li>
                                            <Link title="About" aria-label="About" to="/about">About</Link>
                                        </li>
                                        <li>
                                            <Link title="Services" aria-label="Services" to="/services">Services</Link>
                                        </li>
                                        <li>
                                            <Link title="Projects" aria-label="Projects" to="/projects">Projects</Link>
                                        </li>
                                        <li>

                                            <Link title="Contact" aria-label="" to="/contactUs">Contact</Link>


                                        </li>
                                    </ul>

                                    <Footer/>

                                </div>
                            </div>


                            {/* end mobile navigation */}
                        </div>

                    </div>

                </div>

            </div>


        )
    }
}
