import React from "react"
import {Link} from "gatsby"
import Layout from "./customfooter"
import Styles from '../components/footer.module.css'
class footer extends React.Component {
    render() {
        return (
            <div id="footer">
                    <div className={Styles.get_in_touch}> 
                        <div id={Styles.footerContent}>               
                           <div className="row">
                               <div className="col-lg-7 col-md-7 col-sm-12 col-12" id={Styles.a}>
                                
                                   <div className={Styles.footer_logo_link}>
                                        <Link title="Home" aria-label="Home" to="/"><h3 className={Styles.footer_logo}>
                                            Tandem <br/> & <br/> Stark
                                        </h3></Link>
                                    </div>

                                    <div className={Styles.footer_emails_links}  >
                                        <p><a href="mailto:info@tandemandstark.net">info@tandemandstark.net</a></p>
                                    </div>
                                    <div className={Styles.footer_phone_links}>                                        
                                        <p><a href="tel:+254 (0) 20 2318992">+254 (0) 20 2318992</a></p>
                                        <p><a href="tel:+254 (0) 20 2055945">+254 (0) 20 2055945</a></p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                    <div className={Styles.footer_content} id={Styles.b}>
                                    <div className={Styles.footer_Navmenu_links} >
                                        <ul>
                                            <li>
                                                <Link title="Home" aria-label="Home" to="/">HOME</Link>
                                            </li>
                                            <li>
                                                <Link title="About" aria-label="Home" to="/about">ABOUT</Link>
                                            </li>
                                            <li>
                                                <Link title="Services" aria-label="Home" to="/services">SERVICES</Link>
                                            </li>
                                            <li>
                                                <Link title="Projects" aria-label="Home" to="/projects">PROJECTS</Link>
                                            </li>
                                            <li>
                                                <Link title="Contact" aria-label="Home" to="/contactUs">CONTACT</Link>
                                            </li>
                                        </ul>
                                        
                                    </div>
                                    <div className={Styles.footer_location_links}>
                                    <p><a target="blank" href="https://goo.gl/maps/KQp9TVQGkb3aGzfAA" >Suite No.15, 3rd floor, East Wing, Greenhouse Mall</a></p>
                                    <p><a>P.O. Box 53328-00200, Nairobi, Kenya</a></p>
                                    </div>
                                    </div>
                                    </div>
                                </div>

                           </div>
                  

                    </div>
                 
                <div className="bottom_section">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copyright-text">
                                <p>©  2020 Tandemandstark All rights reserved

                                </p>
                            </div>

                            

                        </div>

                    </div>
                    </div>
            </div>

        )
    }
}

export default footer;
